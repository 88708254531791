@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.action-container {
  display: flex;
  margin-bottom: 20px;
}

::v-deep table th {
  &.col-name {
    width: 25%;
  }
  &.col-email {
    width: 25%;
  }
  &.col-role {
    width: 14%;
  }
  &.col-admin {
    width: 8%;
  }
  &.col-col-tilelytics {
    width: 8%;
  }
  &.col-status {
    width: 12%;
  }
  &.col-actions {
    width: 8%;
  }
}

.inactive-user {
  color: var(--color-text-disabled-theme);
}
